import surveys from './surveys';
import spend from './spend';
import coupons from './coupons';
import bizDevPos from './bizDevPos';
import gen2Registration from './gen2Registration';
import accountManagement from './accountManagement';
import privacyManagement from './privacyManagement';

export default [
  surveys,
  spend,
  coupons,
  bizDevPos,
  gen2Registration,
  accountManagement,
  privacyManagement,
];
