export default {
  id: 'privacyManagement',
  name: 'DSAR',
  description: 'Manage anonymization requests (DSAR) from OneTrust',
  permission: 'fcPrivacyManagement:app',
  icon: 'fa-shield-alt',
  version: '0.1.0',
  routes: [{
    path: '/privacyManagement',
    name: 'PrivacyManagement',
    component: () => import('./PrivacyManagement.vue'),
  }],
};
